//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BlokHorizontalLine',
  props: {
    blok: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: null,
    },
  },
}
